import React from 'react';

import Section from '@latitude/section';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import { Hero } from '@latitude/hero';
import { HeroText } from '../HeroBranded/HeroBranded';

function PageHeaderSection({
  title,
  subTitle,
  pageImage,
  pageImageAlt = '',
  children
}) {
  return (
    <Section
      className="lfs-pageheader"
      css={`
        background-color: #0555c8;
        padding: 0;

        @media (max-width: ${BREAKPOINT.MD}) {
          && > .container.section__content {
            padding: 0;
          }
        }
      `}
    >
      <Hero
        css={`
          && {
            color: #000;
          }

          #hero {
            padding-left: 0;
            padding-right: 0;
          }

          h1 {
            color: #fff;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 20px;
            font-family: Montserrat;
          }

          p {
            color: #fff;
            font-size: 19px;
            font-weight: normal;
          }

          svg {
            min-height: 45px;
            min-width: 45px;
          }

          .HeroContent {
            padding: 17px 0px 59px 0px;
            line-height: normal;
          }

          @media (max-width: ${BREAKPOINT.MD}) {
            p {
              font-size: 20px;
              text-align: center;
            }

            h1 {
              font-size: 35px;
              margin: 5px 0;
              text-align: center;
            }

            .HeroContent {
              padding: 10px;
              background-color: transparent;
            }

            .text-center {
              color: #000 !important;
            }
          }
        `}
        imageContent={
          <div
            className="lfs-pageheader-image"
            css={`
              height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              // border: 2px solid green;
            `}
          >
            <img
              src={pageImage}
              alt={pageImageAlt}
              css={`
                height: 250px;
                width: auto;
                @media (min-width: ${BREAKPOINT.MD}) {
                  height: 300px;
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  height: 400px;
                }
                // border: 3px solid white;
              `}
            />
          </div>
        }
        className="lfs-pageheader-hero"
      >
        <div
          className="lfs-pageheader-text"
          id="hero"
          css={`
              && {
                padding: 0 15px;
                color: #fff:

                @media (min-width: ${BREAKPOINT.LG}) {
                  padding: 0;
                }
              }
            `}
        >
          <h1 className="lfs-pageheader-title">{title}</h1>
          {subTitle && (
            <HeroText
              className="lfs-pageheader-subtitle"
              css={`
                && {
                  color: #fff;
                }
              `}
            >
              {subTitle}
            </HeroText>
          )}
          {children}
        </div>
      </Hero>
    </Section>
  );
}

export default PageHeaderSection;
